import React from "react";

function Forwardleads() {
  return (
    <div>
      <div className="content-wrapper">
        {/* Main content */}
        <section className="content">
          <div className="container">
          
       
    <div className="panel-body">
   <div className="row export-data">
  <div className="col-md-5 col-xs-12">
    <div className="row">
      <div className="col-md-4 col-sm-4 col-xs-6">   
        <div className="btn-group">
          <a className="btn btnes exports" href=" "> <i className="fa fa-plus" />&nbsp;  Add Lead </a>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 mobil-nns col-xs-4">
        <div className="btn-group">
          <a className="btn btnes exports" href=" "> <i className="fa fa-download" aria-hidden="true" />&nbsp;  Import </a>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-6">
        <div className="btn-group">
          <a type="button" id="advS" className="btn btnes exports"><i className="fa fa-search" aria-hidden="true" />&nbsp;  Advance</a>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-7 col-xs-12">
    <div className="ipades">
      <form id="bulkForm" method="POST">
        <div className="row">
          <div className="col-md-3 col-sm-3 col-xs-12">
            <div className="btne-group">
              <p>Bulk Action</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-3 col-xs-12">
            <select className="form-control" name="bstatus" id="bstatus">
              <option value>Change Status</option>
              <option value={11}>Pending</option>
              <option value={7}>Call Back</option>
              <option value={3}>Meeting</option>
              <option value={6}>Invoice Send</option>
              <option value={10}>Won</option>
              <option value={5}>Lost</option>
              <option value={12}>Not Attempt</option>
              <option value={13}>Transferred</option>
            </select>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-12">
            <select className="form-control" name="agent" id="agent">
              <option value>Transfer to</option>
              <option value="MjQ=">Anurag</option>
              <option value="Mjk=">Rahul</option>
              <option value="MzE=">Jassy</option>
              <option value="MzI=">Neha</option>
              <option value="MzQ=">Devrishi</option>
              <option value="MzU=">nisar</option>
            </select>
          </div>
          <div className="col-md-2 col-sm-2 col-xs-12">
            <input type="submit" className="btn btnes btn-primary form-control " defaultValue="Submit" autoComplete="off" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<table id="example" className="table table-striped pt-3" style={{width: '100%'}}>
  <thead>
    <tr>
      <th>Name</th>
      <th>Position</th>
      <th>Office</th>
      <th>Age</th>
      <th>Start date</th>
      <th>Salary</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Tiger Nixon</td>
      <td>System Architect</td>
      <td>Edinburgh</td>
      <td>61</td>
      <td>2011-04-25</td>
      <td>$320,800</td>
    </tr>
    <tr>
      <td>Garrett Winters</td>
      <td>Accountant</td>
      <td>Tokyo</td>
      <td>63</td>
      <td>2011-07-25</td>
      <td>$170,750</td>
    </tr>
    <tr>
      <td>Ashton Cox</td>
      <td>Junior Technical Author</td>
      <td>San Francisco</td>
      <td>66</td>
      <td>2009-01-12</td>
      <td>$86,000</td>
    </tr>
    <tr>
      <td>Cedric Kelly</td>
      <td>Senior Javascript Developer</td>
      <td>Edinburgh</td>
      <td>22</td>
      <td>2012-03-29</td>
      <td>$433,060</td>
    </tr>
    <tr>
      <td>Airi Satou</td>
      <td>Accountant</td>
      <td>Tokyo</td>
      <td>33</td>
      <td>2008-11-28</td>
      <td>$162,700</td>
    </tr>
    <tr>
      <td>Brielle Williamson</td>
      <td>Integration Specialist</td>
      <td>New York</td>
      <td>61</td>
      <td>2012-12-02</td>
      <td>$372,000</td>
    </tr>
    <tr>
      <td>Herrod Chandler</td>
      <td>Sales Assistant</td>
      <td>San Francisco</td>
      <td>59</td>
      <td>2012-08-06</td>
      <td>$137,500</td>
    </tr>
    <tr>
      <td>Rhona Davidson</td>
      <td>Integration Specialist</td>
      <td>Tokyo</td>
      <td>55</td>
      <td>2010-10-14</td>
      <td>$327,900</td>
    </tr>
    <tr>
      <td>Colleen Hurst</td>
      <td>Javascript Developer</td>
      <td>San Francisco</td>
      <td>39</td>
      <td>2009-09-15</td>
      <td>$205,500</td>
    </tr>
    <tr>
      <td>Sonya Frost</td>
      <td>Software Engineer</td>
      <td>Edinburgh</td>
      <td>23</td>
      <td>2008-12-13</td>
      <td>$103,600</td>
    </tr>
    <tr>
      <td>Jena Gaines</td>
      <td>Office Manager</td>
      <td>London</td>
      <td>30</td>
      <td>2008-12-19</td>
      <td>$90,560</td>
    </tr>
    <tr>
      <td>Quinn Flynn</td>
      <td>Support Lead</td>
      <td>Edinburgh</td>
      <td>22</td>
      <td>2013-03-03</td>
      <td>$342,000</td>
    </tr>
    <tr>
      <td>Charde Marshall</td>
      <td>Regional Director</td>
      <td>San Francisco</td>
      <td>36</td>
      <td>2008-10-16</td>
      <td>$470,600</td>
    </tr>
    <tr>
      <td>Haley Kennedy</td>
      <td>Senior Marketing Designer</td>
      <td>London</td>
      <td>43</td>
      <td>2012-12-18</td>
      <td>$313,500</td>
    </tr>
   
  </tbody>
</table>

           
    </div>
          </div>
      </section>  
      </div>
     
    </div>
  );
}
export default Forwardleads;
