// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div>
       <img src="dist/img/notfound.jpg"  className="w-100" style={{height:'700px'}}/> 
    
      
    </div>
  );
};

export default NotFound;